// aboutData.js

const aboutData = [
    {
        description: 'BSc Eng Hons, Computer Science & Eng, University of Moratuwa, Sri Lanka',
        image: 'https://upload.wikimedia.org/wikipedia/en/6/60/University_of_Moratuwa_logo.png',
        scale: 1.2,
    },
    {
        description: 'G.C.E.(A/L) Examination 2019 (Physical Science), AAA | Island Rank 7',
        image: 'https://grace-academy.in/wp-content/uploads/2022/04/man-with-laptop-showing-ok-gesture-4927667-4122903.png',
        scale: 1.65,
    },
    {
        description: 'Successfully completed intern as a full-stack dev at Circles Life.',
        image: 'https://upload.wikimedia.org/wikipedia/commons/a/aa/Circles_Life_RGB_Color_Logo.svg',
        scale: 1,
    },
    {
        description: 'Founder - Softnx Technologies',
        image: 'https://drive.google.com/thumbnail?id=1qF3Q4nrnD4KdxsgV04x5Prw8MsEBb20L',
        scale: 1.7,
        badges: ["full-stack", "mobile","iot"],
    },
    {
        description: 'Freelancer - Level One IOT based Fullstack developer at Fiverr.',
        image: 'https://freelogopng.com/images/all_img/1656738600fiverr-app-logo.png',
        scale: 1,
        badges: ["$1k+ earn", "11+ jobs"],
    },
    {
        description: 'Freelancer - Top Rated Embedded Systems & Fullstack dev. at Upwork.',
        image: 'https://cdn.worldvectorlogo.com/logos/upwork-roundedsquare-1.svg',
        scale: 1,
        badges: ["$2.5K+ earn", "4+ jobs"],
    },
    {
        description: '4yr+ experience with embedded system development technologies.',
        image: 'https://static.vecteezy.com/system/resources/previews/026/428/284/original/smart-home-3d-icon-smart-home-system-concept-internet-of-things-iot-smart-home-and-network-connect-concept-png.png',
        scale: 1.7,
        badges: ["TUYA","esp32","RPi", "C++", "arduino","nodered", "sensors", "actuators","mqtt", 'ble', "wifi","i2c","spi"],
    },
    {
        description: '3yr+ experience with fullstack & mobile technologies.',
        image: 'https://drive.google.com/thumbnail?id=1yOw8d4Lxns4hDyz6eqQWS3JfjzmhK_Qp',
        scale: 1.6,
        badges: ["react", "html", "css", "react-native", "javascript", "python", "flask","java","mysql","postgresql", "firebase", "springboot",'jwt'],
    },
    {
        description: 'Project Management & Software Engineering Background.',
        image: 'https://drive.google.com/thumbnail?id=1jih-WrolTm3RQTZYcQVw_oPo0wz5N8hE',
        scale: 1.5,
        badges: ["git", "jira", "google-vm", "oop","google cloud", "docker","kubernetes","algorithms","database"],
    },
    {
        description: 'Listening musics, Participated Iot seminar series from ACM, Ratnapura District UOM E-fac seminar series, Organizing committee member in Mora Ventures, IEEE membership...',
        image: 'https://drive.google.com/thumbnail?id=1sv7GMNLrd8SHqKURB8ErAsTOg5mSGID4',
        scale: 1,
        badges: ["Conversational in english"]
    },
    
];

export default aboutData;
